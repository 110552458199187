'use client';
import React, { useState } from 'react';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Checkbox,
} from '@nextui-org/react';
import google from '@/assets/svgs/google.png';
import github from '@/assets/svgs/github.png';
import lightLogo from '@/assets/images/companyLogo.png';
import Image from 'next/image';
import { Input } from '@nextui-org/react';
import { useForm } from 'react-hook-form';
import { usePathname } from 'next/navigation';
import { Eye, EyeOff } from 'lucide-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoginSchema } from '@/utils/ValidationSchema';
import { signIn, useSession } from 'next-auth/react';
import { Toaster } from 'react-hot-toast';
import { useRouter } from 'next/navigation';
import toast from 'react-hot-toast';
import './../../../app/globals.css';
import Link from 'next/link';
import { useSearchParamsWrapper } from '@/app/auth/page';

const SignInForm = ({
  isModalOpen,
  closeModal,
  pathname,
  utmParams,
  onSwitch,
  onResetPassword,
}) => {
  const { getParam } = useSearchParamsWrapper();
  const registerRedirect = getParam('redirect');
  const [isResetingPassword, setIsResetingPassword] = useState(false);
  const [isVisiblePass, setisVisiblePass] = useState(false);
  const [keepSignedIn, setKeepSignedIn] = useState(false);
  const [showCheckboxError, setShowCheckboxError] = useState(false);
  const router = useRouter();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(LoginSchema),
  });

  const togglePassVisibility = e => {
    e.preventDefault();
    setisVisiblePass(!isVisiblePass);
  };

  const onsubmit = async data => {
    if (!keepSignedIn) {
      setShowCheckboxError(true);
      return;
    }
  
    setShowCheckboxError(false);
    const signinToast = toast.loading('Logging in..', { duration: 9999 });
  
    try {
      const response = await signIn('credentials', {
        ...data,
        redirect: false,
      });
  
      if (response.ok) {
        toast.dismiss(signinToast);
        toast.success('Logged in successfully');
  
        let redirectPath = '/';
        
        if (registerRedirect) {
          redirectPath = registerRedirect; // If redirect param exists, use it
        } else if (pathname.startsWith('/events/')) {
          // Extract event name from URL
          const eventName = pathname.split('/')[2]; // Get "personal-finance" from "/events/personal-finance"
          redirectPath = `/events/${eventName}/register`;
        }
  
        const utmString = utmParams ? `?utm=${utmParams}` : '';
        const finalRedirectUrl = `${redirectPath}${utmString}`;
  
        router.push(finalRedirectUrl);
        closeModal();
      } else {
        toast.dismiss(signinToast);
        toast.error('Invalid credentials');
      }
    } catch (error) {
      toast.dismiss(signinToast);
      toast.error('An error occurred during login');
      console.error('Login error:', error);
    }
  };
  


  // const handleGoogleSignIn = async () => {
  // const callbackUrl = registerRedirect
  //     ? `${registerRedirect}${utmParams ? '?utm=' + utmParams : ''}`
  //     : pathname   || '/event';

  //   try {
  //     const result = await signIn('google', {
  //       callbackUrl,
  //       redirect: false,
  //     });

  //     if (result?.error) {
  //       toast.error('Error signing in with Google');
  //     } else {
  //       closeModal();
  //       router.push(callbackUrl);
  //     }
  //   } catch (error) {
  //     console.error('Google sign-in error:', error);
  //     toast.error('Error signing in with Google');
  //   }
  // };

  const handleGoogleSignIn = async () => {
    const eventMatch = pathname.match(/^\/events\/([^/]+)/); // Extract event name
    const eventRedirect = eventMatch ? `/events/${eventMatch[1]}/register` : '/';
  
    const callbackUrl = registerRedirect
      ? `${registerRedirect}${utmParams ? '?utm=' + utmParams : ''}`
      : eventRedirect; 
  
    try {
      const result = await signIn('google', {
        callbackUrl,
        redirect: false,
      });
  
      if (result?.error) {
        toast.error('Error signing in with Google');
      } else {
        closeModal();
        router.push(callbackUrl);
      }
    } catch (error) {
      console.error('Google sign-in error:', error);
      toast.error('Error signing in with Google');
    }
  };
  


  return (
    <>
      <Toaster />
      <Modal
        backdrop="blur"
        isOpen={isModalOpen}
        hideCloseButton
        className="md:max-w-[500px] p-4 rounded-3xl"
      >
        <ModalContent>
          <ModalHeader className="flex justify-between items-center">
            <div className="flex-grow flex justify-center">
              <Image src={lightLogo} width={200} alt="" priority />
            </div>
            <Button
              isIconOnly
              justify="end"
              className="absolute top-4 right-6 text-gray-500 font-lg cursor-pointer rounded-full"
              onClick={closeModal}
            >
              <svg
                className="h-5 w-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </Button>
          </ModalHeader>

          <div className="text-start pl-6">
            <div className="font-sans text-xl">Hi,</div>
            <h2 className="text-2xl font-semibold font-sans">
              Welcome back to Hackingly!
            </h2>
          </div>

          <ModalBody>
            <form onSubmit={handleSubmit(onsubmit)}>
              <div className="">
                <div className="flex items-center justify-between my-2">
                  <label
                    htmlFor="email"
                    className="text-md font-sans font-medium text-[#757575]"
                  >
                    Email
                  </label>
                  {errors.email && (
                    <span className="text-red-500 text-sm">
                      {errors.email.message}
                    </span>
                  )}
                </div>
                <Input
                  type="email"
                  id="email"
                  size={'lg'}
                  placeholder="Enter your email address"
                  className="border-1 border-[#757575] rounded-xl w-full bg-none"
                  isRequired
                  {...register('email', { required: true })}
                />
              </div>

              <div>
                <div className="flex items-center justify-between my-2">
                  <label
                    htmlFor="password"
                    className="text-md font-sans font-medium text-[#757575]"
                  >
                    Password
                  </label>
                  {errors.password && (
                    <span className="text-red-500 text-sm">
                      {errors.password.message}
                    </span>
                  )}
                </div>
                <Input
                  type={isVisiblePass ? 'text' : 'password'}
                  id="password"
                  size={'lg'}
                  placeholder="Enter your password"
                  className="border-1 border-[#757575] border rounded-xl w-full focus:outline-none focus:ring focus:ring-indigo-200"
                  isRequired
                  {...register('password')}
                  endContent={
                    <button
                      type="button"
                      className="bg-transparent"
                      size="sm"
                      onClick={togglePassVisibility}
                    >
                      {isVisiblePass ? (
                        <Eye className="text-default-400 pointer-events-none" />
                      ) : (
                        <EyeOff className="text-default-400 pointer-events-none" />
                      )}
                    </button>
                  }
                />
              </div>

              <div className="flex justify-between my-2">
                <div className="flex flex-col">
                  <div className="flex items-center">
                    <Checkbox
                      size="md"
                      color="success"
                      className="text-xs text-gray-600"
                      isSelected={keepSignedIn}
                      onValueChange={setKeepSignedIn}
                    >
                      Keep me signed in
                    </Checkbox>
                  </div>
                  {showCheckboxError && (
                    <span className="text-red-500 text-sm mt-1">
                      Please agree to keep signed in to continue
                    </span>
                  )}
                </div>
                <p>
                  <Link
                    href=""
                    className="text-sm text-green-600"
                    onClick={onResetPassword}
                  >
                    Forgot your password?
                  </Link>
                </p>
              </div>

              {errors.root && (
                <div className="text-red-500 text-sm mt-2">
                  {errors.root.message}
                </div>
              )}
              <Button
                type="submit"
                disabled={!isValid}
                size="lg"
                className={`w-full bg-[#097969] text-white font-semibold py-2 rounded-md shadow-custom-inner-2 shadow-custom-inner-1 transition duration-200 mb-4 ${
                  !isValid ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                Login
              </Button>

              <div className="text-center w-full mb-3">
                <p className="text-sm text-gray-600">
                  Don't have an account?{' '}
                  <Link href="" onClick={onSwitch} className="text-green-600">
                    Sign up
                  </Link>
                </p>
              </div>

              <div className="flex items-center">
                <div className="flex-grow border-t border-gray-300"></div>
                <span className="mx-8 text-gray-600">OR</span>
                <div className="flex-grow border-t border-gray-300"></div>
              </div>
            </form>
          </ModalBody>

          <ModalFooter>
            <div className="flex flex-col justify-between gap-3 w-full mb-2">
              <Button
                onPress={handleGoogleSignIn}
                className="w-full bg-white border border-gray-600 gap-4 text-md py-6 font-sans font-semibold text-[#000000] flex items-center justify-center"
              >
                <Image src={google} alt="Google" className="w-7 h-7" />
                Continue with Google
              </Button>
              <Button
                onPress={() => {
                  if (!keepSignedIn) {
                    setShowCheckboxError(true);
                    return;
                  }
                  setShowCheckboxError(false);
                  signIn('github', {
                    callbackUrl: registerRedirect
                      ? `${registerRedirect}${utmParams ? '?utm=' + utmParams : ''}`
                      : '/event',
                  }).catch(() => {
                    toast.error('Error signing in with Github');
                  });
                }}
                className="w-full bg-white border border-gray-600 gap-4 text-md py-6 font-sans font-semibold text-[#000000] flex items-center justify-center"
              >
                <Image src={github} alt="GitHub" className="w-7 h-7" />
                Continue with GitHub
              </Button>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SignInForm;

