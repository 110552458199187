'use client';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalFooter,
  button,
  Input,
} from '@nextui-org/react';
import Link from 'next/link';
import React, { useState } from 'react';
import { useRouter } from 'next/navigation';
import { OTPSchema } from '@/utils/ValidationSchema';
import axios from 'axios';
import { baseURL } from '@/utils/BaseURL';
import lightLogo from '@/assets/images/companyLogo.png';
import Image from 'next/image';
import { Eye, EyeOff } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { Checkbox } from '@nextui-org/react';
import toast, { Toaster } from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { signIn } from 'next-auth/react';
import { useSearchParamsWrapper } from '@/app/auth/page';

const RegisterForm = ({
  isModalOpen,
  closeModal,
  onSwitch,
  pathname,
  utmParams,
}) => {
  const { getParam } = useSearchParamsWrapper();
  const registerRedirect = getParam('redirect');
  // const utmParams = urlParams.get('utm');

  const router = useRouter();
  const [formData, setFormData] = useState(null);
  const [showOTPForm, setShowOTPForm] = useState(false);
  const [isVisiblePass, setisVisiblePass] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [showCheckboxError, setShowCheckboxError] = useState(false);

  const togglePassVisibility = e => {
    e.preventDefault();
    setisVisiblePass(!isVisiblePass);
  };

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    reset: resetForm,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
  });

  const {
    register: register2,
    handleSubmit: handleSubmitOTP,
    setError: setErrorOTP,
    formState: { errors: errors2 },
    reset: resetOTPForm,
  } = useForm({
    resolver: yupResolver(OTPSchema),
  });

  const onSubmit = async data => {
    if (!acceptedTerms) {
      setShowCheckboxError(true);
      return;
    }
    const { firstName, lastName, email, password, username } = data;
    const requestedData = {
      first_name: firstName,
      last_name: lastName,
      username: username,
      email,
      password,
    };

    if (data.password !== data.confirmPassword) {
      setError('confirmPassword', {
        message: 'Passwords do not match',
      });
      return;
    }

    const toastId = toast.loading('Sending OTP...');

    try {
      const response = await axios.post(
        `${baseURL}/users/register/`,
        requestedData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 201) {
        toast.dismiss(toastId);
        toast.success('OTP sent to your email');
        setFormData(requestedData);
        setShowOTPForm(true);
      }
    } catch (err) {
      console.error('Registration Error:', err.response?.data);
      toast.dismiss(toastId);

      if (err.response?.data) {
        if (err.response.data.errors) {
          const errors = err.response.data.errors;
          if (errors.email) {
            setError('email', {
              message: 'Email already exists',
            });
            toast.error('Email already exists');
          } else if (errors.username) {
            setError('username', {
              message: 'Username already exists',
            });
            toast.error('Username already exists');
          } else {
            const errorMessage = Object.values(errors)[0];
            toast.error(
              errorMessage || 'An error occurred during registration'
            );
          }
        } else if (err.response.data.detail) {
          toast.error(err.response.data.detail);
        } else {
          toast.error('Server error: ' + JSON.stringify(err.response.data));
        }
      } else {
        toast.error('Network error: Unable to connect to server');
      }
    }
  };

  const onOTPSubmit = async otpData => {
    const { otp } = otpData;

    const payload = JSON.stringify({
      email: formData.email,
      otp: otp.toString(),
    });

    try {
      const signupToast = toast.loading('Creating account..', {
        duration: 9999,
      });
      const otpResponse = await axios.post(
        `${baseURL}/users/verify/otp/`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      toast.dismiss(signupToast);
      toast.success('Account created successfully', {
        duration: 2500,
      });

      // Sign in the user
      setTimeout(async () => {
        const signInResponse = await signIn('credentials', {
          email: formData.email,
          password: formData.password,
          redirect: false,
        });

        if (signInResponse.ok) {
          toast.success('Logged in successfully');
          // Close the modal
          closeModal();

          // Handle redirection
          if (registerRedirect) {
            router.push(
              `${registerRedirect}${utmParams ? '?utm=' + utmParams : ''}`
            );
          } else if (pathname) {
            router.push(`${pathname}${utmParams ? '?utm=' + utmParams : ''}`);
          } else {
            router.push('/events');
          }
        } else {
          toast.error('Error logging in');
        }
      }, 0);
    } catch (error) {
      if (error.response) {
        const { status } = error.response;
        setErrorOTP('otp', {
          message: status === 400 ? 'Incorrect OTP' : 'Invalid',
        });
        toast.error('Invalid OTP');
      }
    }
  };

  // const handleOTPback = async () => {
  //   resetOTPForm();
  //   resetForm();
  //   handleHideSwitch(false);
  //   setShowOTPForm(false);
  // };
  const handleOTPback = async () => {
    resetOTPForm(); 
    resetForm(); 
    setShowOTPForm(false); 
  };

  return (
    <>
      <div>
        <Toaster />
      </div>
      <Modal
        backdrop="blur"
        isOpen={isModalOpen}
        hideCloseButton
        className="  md:max-w-[500px] rounded-3xl mt-4 md:max-h-[101vh] overflow-y-auto "
      >
        <ModalContent >
          <ModalHeader className="flex justify-between items-center">
            <div className="flex-grow flex justify-center">
              <Image src={lightLogo} width={200} alt="" priority />
            </div>
            {/* Close Button */}
            <Button
              isIconOnly
              justify="end"
              className=" absolute top-8 right-6 text-gray-500 font-lg cursor-pointer rounded-full"
              onClick={closeModal}
            >
              <svg
                className="h-5 w-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </Button>
          </ModalHeader>
          {/* <div className="text-start  pl-6">
            <div className="font-sans text-xl">Hi,</div>
            <h2 className="text-2xl font-semibold font-sans">
              Welcome back to Hackingly!
            </h2>
          </div> */}
          <ModalBody >
            {showOTPForm ? (
              <div className="flex h-full flex-col justify-between">
                <div className="flex h-full flex-col justify-center">
                  <form
                    onSubmit={handleSubmitOTP(onOTPSubmit)}
                    className="animate__animated animate__fadeIn align-flex-middle"
                  >
                    <div className="flex flex-col gap-4">
                      <div>
                        <h1 className="text-2xl font-medium">
                          An OTP has been sent to your email
                        </h1>
                      </div>
                      <Input
                        type="text"
                        label="OTP"

                        variant="faded"
                        color="primary"
                        {...register2('otp', { required: true })}
                        isInvalid={errors2?.otp}
                        errorMessage={errors2?.otp?.message}
                      />
                      <Button
                        // color="primary"
                        size="lg"
                        type="submit"
                        className="text-lg bg-[#097969] text-white font-semibold py-2 rounded-md hover:bg-[#005D44] shadow-custom-inner-2 shadow-custom-inner-1 transition duration-200 mb-4"
                      >
                        Submit
                      </Button>
                    </div>
                  </form>
                </div>
                <div>
                  <Button
                    type="button"
                    variant="faded"
                    color="primary"
                    className="mb-2 text-md sm:text-base md:text-sm w-48 h-fit z-20 py-1"
                    onClick={handleOTPback}
                  >
                    Back to Signup
                  </Button>
                </div>
              </div>
            ) : (
              <>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <div className="flex items-center justify-between">
                      <label
                        htmlFor="name"
                        className="block my-2  font-sans  text-[#757575]"
                      >
                        Full Name
                      </label>
                      {errors.firstName && (
                        <span className="text-red-500 text-sm">
                          {errors.firstName.message}
                        </span>
                      )}
                    </div>
                    <Input
                      placeholder="Enter your Full Name"
                      isRequired
                      size="md"
                      required
                      {...register('firstName', {
                        required: 'Full Name is required',
                      })}
                      // isInvalid={errors.firstName}
                      // errorMessage={errors.firstName?.message}
                      className=" border-1 border-[#757575] rounded-xl w-full bg-none"
                    />
                  </div>
                  <div>
                    <div className="flex items-center justify-between ">
                      <label
                        htmlFor="username"
                        className="block my-2 font-sans  text-[#757575]"
                      >
                        Username
                      </label>
                      {errors.username && (
                        <span className="text-red-500 text-sm">
                          {errors.username.message}
                        </span>
                      )}
                    </div>
                    <Input
                      placeholder="Enter your Username"
                      size="md"
                      isRequired
                      className="border-1 border-[#757575] rounded-xl w-full bg-none"
                      {...register('username', {
                        required: true,
                        pattern: {
                          value: /^[a-zA-Z0-9_]+$/,
                          message:
                            'Username can only contain letters, numbers and underscores',
                        },
                        minLength: {
                          value: 3,
                          message:
                            'Username must be at least 3 characters long',
                        },
                      })}
                      isInvalid={errors.username}
                      // errorMessage={errors.username?.message}
                      // className="border-1 border-[#757575] rounded-xl w-full bg-none"
                    />
                  </div>
                  <div>
                    <div className="flex items-center justify-between ">
                      <label
                        htmlFor="email"
                        className="block my-2 font-sans  text-[#757575]"
                      >
                        Email Address
                      </label>
                      {errors.email && (
                        <span className="text-red-500 text-sm">
                          {errors.email.message}
                        </span>
                      )}
                    </div>
                    <Input
                      placeholder="Enter your email address"
                      className=" border-1 border-[#757575] rounded-xl w-full bg-none"
                      isRequired
                      size="md"
                      {...register('email', {
                        required: true,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'Invalid email address',
                        },
                      })}
                      // isInvalid={errors.email}
                      // errorMessage={errors.email?.message}
                    />
                  </div>
                  <div>
                    <div className="flex items-center justify-between">
                      <label
                        htmlFor="passwrod"
                        className="block my-2 font-sans  text-[#757575]"
                      >
                        Create a Password
                      </label>
                      {errors.password && (
                        <span className="text-red-500 text-sm">
                          {errors.password.message}
                        </span>
                      )}
                    </div>
                    <Input
                      type={isVisiblePass ? 'text' : 'password'}
                      placeholder="Enter your Password"
                      className="border-1 border-[#757575] rounded-xl w-full focus:outline-none "
                      isRequired
                      {...register('password', {
                        required: true,
                        minLength: {
                          value: 8,
                          message:
                            'Password must be at least 8 characters long',
                        },
                      })}
                      // isInvalid={errors.password}
                      // errorMessage={errors.password?.message}
                      size="md"
                      endcontent={
                        <button
                          type="button"
                          className="bg-transparent"
                          size="xs"
                          onClick={togglePassVisibility}
                        >
                          {isVisiblePass ? (
                            <Eye className="text-default-400 pointer-events-none" />
                          ) : (
                            <EyeOff className="text-default-400 pointer-events-none" />
                          )}
                        </button>
                      }
                    />
                  </div>
                  <div>
                    <div className="flex items-center justify-between ">
                      <label
                        htmlFor="passwrod"
                        className="block my-2 font-sans  text-[#757575]"
                      >
                        Confirm a Password
                      </label>
                      {errors.confirmPassword && (
                        <span className="text-red-500 text-sm">
                          {errors.confirmPassword.message}
                        </span>
                      )}
                    </div>
                    <Input
                      isRequired
                      size="md"
                      placeholder="Confirm password"
                      className="border-1 border-[#757575] rounded-xl w-full focus:outline-none "
                      // variant="faded"
                      // color="primary"
                      {...register('confirmPassword', {
                        required: true,
                        validate: value =>
                          value === getValues('password') ||
                          'Passwords do not match',
                      })}
                      // isInvalid={errors.confirmPassword}
                      // errorMessage={errors.confirmPassword?.message}
                      endContent={
                        <button
                          className="focus:outline-none"
                          type="button"
                          onClick={togglePassVisibility}
                        >
                          {isVisiblePass ? (
                            <EyeOff className="text-2xl text-default-400 pointer-events-none" />
                          ) : (
                            <Eye className="text-2xl text-default-400 pointer-events-none" />
                          )}
                        </button>
                      }
                      type={isVisiblePass ? 'text' : 'password'}
                    />
                  </div>
                  <div className="flex items-center my-2 ">
                    <Checkbox
                      size="md"
                      color="success"
                      isSelected={acceptedTerms}
                      onValueChange={value => {
                        setAcceptedTerms(value);
                        setShowCheckboxError(false); // Clear error when checkbox is checked
                      }}
                    >
                      <span className="text-sm text-gray-600">
                        I accept the{' '}
                        <Link href="" className="text-green-600">
                          Terms of Service & Privacy Policy
                        </Link>
                      </span>
                    </Checkbox>
                  </div>
                  {showCheckboxError && (
                    <p className="text-red-500 text-sm mt-1">
                      Please accept the Terms of Service & Privacy Policy
                    </p>
                  )}
                  {/* <label
                          htmlFor="keepSignedIn"
                          className="ml-2 text-sm text-gray-600"
                        >
                          Keep me signed in
                        </label> */}

                  <Button
                    type="submit"
                    disabled={!isValid}
                    size="md"
                    // className="w-full bg-[#097969] font-sans text-lg py-6 text-white  rounded-xl hover:bg-[#005D44] shadow-custom-inner-2 shadow-custom-inner-1 transition duration-200 mb-4"
                    className={`w-full bg-[#097969] text-white font-semibold py-2 rounded-md shadow-custom-inner-2 shadow-custom-inner-1 transition duration-200 mb-4 ${
                      !isValid ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                  >
                    SignUp
                  </Button>
                  <div className="text-center w-full ">
                    <p className="text-sm text-gray-600">
                      Already have an account?{' '}
                      <Link
                        href=""
                        onClick={onSwitch}
                        className="text-green-600"
                      >
                        Sign in
                      </Link>
                    </p>
                  </div>
                </form>
              </>
            )}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RegisterForm;

